<template>
    <section
        class="cards-carousel overflow-hidden relative"
        :class="`cards-carousel--${backgroundColor} bg-${backgroundColor} text-${oppositeBackgroundColor} ${spacing}`"
    >

        <div v-if="anchorId" class="anchor">
            <div class="anchor__element" :id="anchorId"></div>
        </div>
        <div class="container grid-container">
            <div class="flex justify-between items-end mb-6">
                <div class="flex flex-col w-full md:w-7/12">
                    <h2>{{ title }}</h2>
                    <div v-if="text" v-html="text" class="mt-4"></div>
                </div>
            </div>
        </div>
        <div class="container grid-container relative">
            <div v-if="areButtonsVisible" class="hidden md:flex">
                <button class="carousel-btn carousel-btn--previous" @click.prevent.stop="previous">Précédent</button>
            </div>
            <div class="-mx-1 lg:-mx-3">
                <div :class="{ 'cards-carousel__container' : !isFullGrid }">
                    <carousel
                        :per-page-custom="cardsPerPage"
                        :pagination-enabled="false"
                        :navigate-to="currentSlide"
                        :scroll-per-page="false"
                        :mouse-drag="true"
                        :touch-drag="true"
                        ref="carousel"
                    >
                        <slot></slot>
                    </carousel>
                </div>
            </div>
        </div>
        <div v-if="areButtonsVisible" class="hidden md:flex">
            <button class="carousel-btn carousel-btn--next ml-5" @click.prevent.stop="next">Suivant</button>
        </div>
    </section>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
    name: "CardsCarousel",

    components: {
        Carousel
    },

    props: {
        backgroundColor: {
            type: String,
            default: '#fff',
        },

        title: {
            type: String,
            default: '',
        },

        anchorId: {
            type: String,
            default: null,
        },

        text: {
            type: String,
            default: '',
        },

        hasSpacing: {
            type: Boolean,
            default: true,
        },

        isFullGrid: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            currentSlide: 0,
            areButtonsVisible: true,
        }
    },

    computed: {
        slideCount() {
            return this.$refs.carousel.pageCount;
        },

        oppositeBackgroundColor() {
            return this.backgroundColor === 'black' ? 'white' : 'black';
        },

        spacing() {
            if(!this.hasSpacing) {
                return;
            }

            return this.backgroundColor === 'black' ? 'py-10 md:pt-14 md:pb-16' : 'my-10 md:mt-14 md:mb-16'
        },

        cardsPerPage() {
            return this.isFullGrid ? [[320, 1], [768, 2]] : [[320, 1], [768, 2], [992, 3]];
        },
    },

    mounted() {
        this.areButtonsVisible = this.slideCount > 1;
    },

    methods: {
        next() {
            if (this.currentSlide === this.slideCount - 1) {
                this.currentSlide = 0;
                return;
            }

            this.currentSlide++;
        },

        previous() {
            if (this.currentSlide === 0) {
                this.currentSlide = this.slideCount - 1;
                return;
            }

            this.currentSlide--;
        },
    },
}
</script>

<style scoped lang="scss">
.cards-carousel {
    &__container {
        max-width: 63rem;
    }

    ::v-deep .VueCarousel-wrapper {
        overflow: visible;
    }

    ::v-deep .VueCarousel-slide {
        @apply px-1 md:px-3;
    }

    &--black {
        ::v-deep .article-card__text-container {
            @apply bg-white text-black;
        }
        ::v-deep .article-card__thematic {
            @apply text-black;
        }
        ::v-deep .article-card__like-container {
            @apply border-gray-200;
        }

        ::v-deep .article-card-sound__text-container {
            @apply text-white;
        }
        ::v-deep .article-card-sound__thematic {
            @apply text-brand;
        }
        ::v-deep .article-card-sound__tag {
            @apply bg-opacity-20;
        }

        ::v-deep .article-card-video__text-container {
            @apply text-white;
        }
        ::v-deep .article-card-video__thematic {
            @apply text-brand;
        }
        ::v-deep .article-card-video__tag {
            @apply bg-opacity-20;
        }
    }
}
</style>

<template>
    <section class="youtube-video article-grid-container container mb-10 md:mb-16 mt-5">
        <div v-if="isYoutube" class="relative aspect-w-16 aspect-h-9">
            <iframe class="absolute inset-0 w-full h-full"
                    allowfullscreen="1"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    title="YouTube video player"
                    :src="`https://www.youtube.com/embed/${idYoutube}?enablejsapi=1&amp;widgetid=1`"
                    id="widget2"
                    width="640"
                    height="360"
                    frameborder="0"
            ></iframe>
        </div>
        <video v-else :src="videoUrl+'#t=0.001'" preload="metadata" controls></video>
    </section>
</template>

<script>
    import getYouTubeID from 'get-youtube-id';

    export default {
        name: "RemoteVideo",
        props:  {
            videoUrl: {type: String, default: null},
        },
        computed: {
            idYoutube() {
                return getYouTubeID(this.videoUrl);
            },
            isYoutube() {
                return this.videoUrl.includes('youtube');
            }
        }
    }
</script>

<style scoped lang="scss">

</style>

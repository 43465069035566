<template>
    <div class="menu-overlay fixed inset-0 bg-white w-screen z-20 px-4 md:px-0 pt-12 md:pt-0">
        <button class="absolute top-0 right-0 flex flex-col md:flex-row mt-6 mr-6" @click="$emit('close')">
            <img src="~@/static/images/icons/cross.svg" alt="Fermer">
        </button>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "MenuOverlay"
}
</script>

<style scoped lang="scss">
.menu-overlay {
    @apply h-screen;
}

body.toolbar-horizontal.toolbar-tray-open .header-nav .menu-overlay {
    top: 79px;
    height: calc(100vh - 79px);
}

body.toolbar-vertical .header-nav .menu-overlay,
body.toolbar-horizontal .header-nav .menu-overlay {
    top: 39px;
    height: calc(100vh - 39px);
}
</style>

<template>
    <transition name="reveal">
        <slot></slot>
    </transition>
</template>

<script>
export default {
    name: "Reveal"
}
</script>

<style scoped lang="scss">
.reveal-enter-active, .reveal-leave-active {
    transition: width 200ms;
}

:not(#slp) {
    .reveal-enter,
    .reveal-leave-to,
    .reveal-leave {
        width: 0;
    }
}
</style>

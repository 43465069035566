<template>
    <component :is="tag" @mouseover="tag = 'a'" @mouseleave="tag = 'span'" :href="tag ==='a' ? href : false">
        <slot></slot>
    </component>
</template>

<script>
    export default {
        name: "ObfuscatedLink",

        props: {
            href: { type: String, required: true },
        },

        data() {
            return {
                tag: 'span',
            };
        },
    }
</script>

<template>
    <intersect @enter="initCarousel" :threshold="[0.2]">
        <section class="testimonials-video overflow-hidden relative bg-cover bg-center text-white md:py-16" :style="`background-image: url('${backgroundImage}')`">
            <div class="relative z-10 container grid-container">
                <h2 class="absolute left-4 top-10 m:left-8 md:top-16">{{ title }}</h2>
            </div>
            <div class="testimonials-video__carousel-container relative mx-auto">
                <div class="testimonials-video__folio absolute inset-x-2 md:inset-x-24 z-10 top-4 flex">
                    <div
                        v-for="i in slidesNumber"
                        :key="i"
                        class="testimonials-video__folio-item overflow-hidden relative mx-2 h-1 rounded-full"
                        :class="getFolioItemsClasses(i-1)"
                    ></div>
                </div>
                <carousel
                    ref="carousel"
                    :per-page="1"
                    :center-mode="true"
                    :pagination-enabled="false"
                    :navigation-enabled="true"
                    :navigate-to="currentSlideId"
                    v-model="currentSlideId"
                >
                    <slot></slot>
                </carousel>
            </div>
        </section>
    </intersect>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
import Intersect from 'vue-intersect';

export default {
    name: "TestimonialsVideo",

    components: {
        Carousel,
        Intersect,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        backgroundImage: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            currentSlideId: 0,
            hasSwiped: false,
            firstSlide: null,
            slidesNumber: 0,
            intervalId: null,
            isStarted: false,
        }
    },

    watch: {
        currentSlideId(newId, oldId) {
            clearInterval(this.intervalId);
            this.startInterval();

            // Fix Vue Carousel issue, see https://github.com/SSENSE/vue-carousel/issues/390
            if(!this.hasSwiped) {
                this.firstSlide.classList.remove('VueCarousel-slide-active');
                this.hasSwiped = true;
            }

            let slides = this.$el.querySelectorAll('.testimonials-video__slide');

            const oldSlideVideo = slides[oldId].querySelector('.testimonials-video__slide-video');

            if (oldSlideVideo) {
                oldSlideVideo.pause();
                oldSlideVideo.currentTime = 0;
            }

            const newSlideVideo = slides[newId].querySelector('.testimonials-video__slide-video');

            if (newSlideVideo) {
                newSlideVideo.play();
            }
        }
    },

    destroyed() {
        clearInterval(this.intervalId);
    },

    methods: {
        getFolioItemsClasses(i) {
            let result = '';

            if (i < this.currentSlideId) {
                result = 'testimonials-video__folio-item--filled';
            } else if (i === this.currentSlideId) {
                result = 'testimonials-video__folio-item--current';
            }

            return result += ` w-1/${this.slidesNumber}`;
        },

        nextSlide() {
            this.currentSlideId === this.slidesNumber-1 ? this.currentSlideId = 0 : this.currentSlideId++;
        },

        initCarousel() {
            if (this.isStarted) {
                return;
            }

            this.isStarted = true;

            // Fix Vue Carousel issue, see https://github.com/SSENSE/vue-carousel/issues/390
            this.firstSlide = this.$refs.carousel.getSlide(0).$el;
            this.firstSlide.classList.add('VueCarousel-slide-active');

            const firstSlideVideo = this.firstSlide.querySelector('.testimonials-video__slide-video');

            if (firstSlideVideo) {
                firstSlideVideo.play();
            }

            this.slidesNumber = this.$refs.carousel.pageCount;

            this.startInterval();
        },

        startInterval() {
            this.intervalId = setInterval(this.nextSlide, 15000);
        }
    }
}
</script>

<style scoped lang="scss">
.testimonials-video {
    &__carousel-container {
        @screen md {
            width: 34rem;
        }
    }

    &__folio-item {
        background-color: rgba(0, 255, 255, 0.4);

        &::after {
            @apply absolute inset-0 w-0 h-full bg-brand;
            content: '';
        }

        &--filled {
            @apply bg-brand;
        }

        &--current {
            &::after {
                animation: fill 15s linear;

                @keyframes fill {
                    from {
                        @apply w-0;
                    }

                    to {
                        @apply w-full;
                    }
                }
            }
        }
    }

    ::v-deep .VueCarousel-wrapper {
        overflow: visible;
    }

    ::v-deep .VueCarousel-slide {
        @apply opacity-60 transition-opacity duration-500;

        .testimonials-video__slide-text {
            @apply relative opacity-0;

            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(0.5rem);

            transition: opacity 500ms;
        }

        &-active {
            @apply opacity-100;

            .testimonials-video__slide {
                @screen md {
                    @apply ring-8 ring-white rounded-2xl;
                }
            }

            .testimonials-video__slide-text {
                @apply opacity-100;
            }
        }

        @screen md {
            padding: 0 5.3125rem;
        }
    }

    ::v-deep &__slide {
        height: 41.6875rem;
    }

    ::v-deep .VueCarousel-navigation-button {
        @apply w-12 h-12 bg-center bg-cover;
        font-size: 0;

        @screen md {
            @apply w-18 h-18;
        }
    }

    ::v-deep .VueCarousel-navigation--disabled {
        @apply hidden;
    }

    ::v-deep .VueCarousel-navigation-next {
        background-image: url('~@/static/images/icons/carousel-arrow-next.svg');
        transform: translate(-50%, -50%);

        @screen md {
            transform: translate(50%, -50%);
        }
    }

    ::v-deep .VueCarousel-navigation-prev {
        background-image: url('~@/static/images/icons/carousel-arrow-previous.svg');
        transform: translate(50%, -50%);

        @screen md {
            transform: translate(-50%, -50%);
        }
    }
}
</style>

<template>
    <div class="header-nav relative pt-16 md:pt-24">
        <header class="header-nav__banner fixed top-0 z-20 inset-x-0 w-full h-16 md:h-24 bg-white shadow-md">
            <div class="flex justify-between items-center h-full px-4 md:px-8">
                <a :href="homepageUrl" title="Accueil">
                    <img src="~@/static/images/brand/logo-tilt-black.svg" :alt="content.logoAlt ? content.logoAlt.text : 'Tilt logo'" class="header-nav__logo">
                </a>

                <p class="hidden md:flex">
                    <span class="bg-black text-white px-1 header-nav__baseline">
                        {{content.baseline.bgBlack}}
                    </span>
                    <span class="bg-brand px-1 header-nav__baseline">
                        {{content.baseline.bgBlue}}
                    </span>
                </p>

                <div class="flex items-center">
                    <a href="/jagis" class="btn btn--ghost-black md:mr-6">J'agis</a>

                    <header-search-form class="ml-4 md:ml-0 md:mr-6"></header-search-form>

                    <button class="hidden md:block rounded-full focus:ring focus:ring-brand focus:outline-none" @click="isMenuOpened = true">
                        <img src="~@/static/images/icons/burger.svg" alt="Menu">
                    </button>
                </div>
            </div>

            <div class="header-nav__scroll-indicator absolute top-full h-2 bg-black" :style="`width: ${scrollPercent}%;`"/>
        </header>

        <button class="header-nav__burger-mobile fixed z-10 right-4 bottom-4 md:hidden shadow-3xl rounded-full" @click="isMenuOpened = true">
            <img src="~@/static/images/icons/burger.svg" alt="Menu">
        </button>

        <fade>
            <menu-overlay v-if="isMenuOpened" @close="isMenuOpened = false">
                <div class="flex flex-col w-full h-full">
                    <div class="flex flex-col md:flex-row md:flex-1 w-full">
                        <div class="flex flex-row md:flex-col md:justify-center items-center w-full md:w-1/3 lg:w-1/4 py-4 border-b md:border-b-0 md:border-r border-gray-200">
                            <div class="header-nav__emoji mr-2 md:mr-0">
                                {{ content.column1.emoji }}
                            </div>

                            <a :href="content.column1.homepage_url" class="header-nav__column-title font-anton font-normal no-underline">{{ content.column1.title }}</a>
                        </div>

                        <div class="flex flex-col md:justify-center md:items-center w-full md:w-1/3 lg:w-1/4 py-4 border-b md:border-b-0 md:border-r border-gray-200">
                            <div class="flex md:flex-col items-center md:justify-center">
                                <div class="header-nav__emoji mr-2 md:mr-0">
                                    {{ content.column2.emoji }}
                                </div>

                                <p class="header-nav__column-title font-anton font-normal">{{ content.column2.title }}</p>
                            </div>

                            <ul class="list-none flex flex-col md:items-center md:text-center pl-8 md:pl-0">
                                <li v-for="(item, key) in content.column2.thematics" :key="`thematic-${key}`" class="mt-2 md:mt-6">
                                    <a :href="item.url" class="header-nav__link font-normal no-underline text-base md:text-xl">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="flex flex-col md:justify-center md:items-center w-full md:w-1/3 lg:w-1/4 py-4 border-b md:border-b-0 md:border-r border-gray-200">
                            <div class="inline-block">
                                <div class="flex items-center md:flex-col md:text-center mb-1 md:mb-5">
                                    <div class="header-nav__emoji mr-2 md:mr-0">
                                        {{ content.column3.emoji }}
                                    </div>

                                    <p class="header-nav__column-title font-anton font-normal">{{ content.column3.title }}</p>
                                </div>
                                <ul class="list-none flex flex-wrap md:items-center md:justify-center mt-1 md:mt-0 -mb-1 md:mb-0 md:px-10">
                                    <li v-for="(item, key) in content.column3.tags" :key="`tag-${key}`" class="p-1">
                                        <a :href="item.url" class="block border border-gray-500 rounded-md p-2 font-roboto font-normal text-sm text-gray-500 no-underline hover:bg-gray-100 hover:opacity-100 transition-colors duration-200">{{ item.name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="flex flex-col md:justify-center md:items-center w-full md:w-1/3 lg:w-1/4 py-4 border-b md:border-b-0 md:border-r border-gray-200">
                            <div class="flex md:flex-col items-center md:justify-center">
                                <div class="header-nav__emoji mr-2 md:mr-0">
                                    {{ content.column5.emoji }}
                                </div>

                                <p class="header-nav__column-title font-anton font-normal">{{ content.column5.title }}</p>
                            </div>

                            <ul class="list-none flex flex-col md:items-center md:text-center pl-8 md:pl-0">
                                <li v-for="(item, key) in content.column5.formats" :key="`type-${key}`" class="mt-2 md:mt-6">
                                    <a :href="item.url" class="header-nav__link font-normal no-underline text-base md:text-xl" rel="nofollow">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="flex flex-col md:justify-center md:items-center w-full md:w-1/3 lg:w-1/4 py-4 border-b md:border-b-0 border-gray-200">
                            <div class="flex md:flex-col items-center md:justify-center">
                                <div class="header-nav__emoji mr-2 md:mr-0">
                                    {{ content.column4.emoji }}
                                </div>

                                <a href="/mon-grand-mix" class="header-nav__column-title font-anton font-normal no-underline text-center" rel="nofollow">{{ content.column4.title }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col md:flex-row md:border-t border-gray-200">
                        <ul class="list-none flex flex-col md:flex-row md:items-center my-6">
                            <li v-for="(item, key) in content.bottomLinks" :key="`link-${key}`" class="py-2">
                                <a :href="item.url" class="header-nav__link font-bold text-base md:text-xl no-underline md:p-4 md:mx-4" rel="nofollow">{{ item.name }}</a>
                            </li>
                        </ul>

                        <ul class="list-none flex md:items-center md:ml-auto md:mr-5">
                            <li v-for="(item, key) in content.socialNetworks" :key="`social-${key}`" class="mr-6 md:mx-3 w-6 h-6 md:w-10 md:h-10">
                                <a :href="item.url" :title="item.name" target="_blank" rel="nofollow">
                                    <img :src="item.pictoUrl" :alt="item.name">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </menu-overlay>
        </fade>
    </div>
</template>

<script>
import Fade from "@/app/transitions/Fade";
import MenuOverlay from "@/app/components/MenuOverlay";
import HeaderSearchForm from "@/app/components/HeaderSearchForm";

export default {
    name: "HeaderNav",

    props: {
        homepageUrl: {
            type: String,
            default: null,
        },

        content: {
            type: Object,
            required: true,
        },
    },

    components: {
        Fade,
        MenuOverlay,
        HeaderSearchForm,
    },

    data() {
        return {
            isMenuOpened: false,
            scrollPercent: 0,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
    },

    destroyed() {

        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        scrollHandler() {
            this.scrollPercent = 100 * window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
        }
    }
}
</script>

<style scoped lang="scss">
.header-nav {
    &__scroll-indicator {
        &::after {
            @apply absolute inset-y-0 left-full w-4 h-2 bg-brand;
            content: '';
            margin-left: 0.625rem;
        }
    }

    &__baseline {
        @apply font-anton font-normal;
        font-size: 1.2625rem;
        line-height: 1.11;
    }

    &__logo {
        width: 3.9375rem;
        height: 2.5rem;

        @screen md {
            width: 6.0625rem;
            height: 3.9375rem;
        }
    }

    &__emoji {
        font-size: 1.125rem;
        line-height: 1.33;

        @screen md {
            font-size: 4rem;
            line-height: normal;
        }
    }

    &__column-title {
        font-size: 1.5rem;
        line-height: 1.33;

        @screen md {
            font-size: 2.5rem;
            line-height: 1.4;
        }
    }

    &__link {
        line-height: 1.5;

        @screen md {
            line-height: 0.85;
        }
    }
}

body.toolbar-horizontal.toolbar-tray-open .header-nav .header-nav__banner {
    top: 79px;
}

body.toolbar-vertical .header-nav .header-nav__banner,
body.toolbar-horizontal .header-nav .header-nav__banner {
    top: 39px;
}
</style>

require('typeface-anton');
require('typeface-roboto');
import '@/assets/styles/main.scss';

import Vue from 'vue';
import HeaderNav from "@/app/components/HeaderNav";
import RemoteVideo from "@/app/components/RemoteVideo";
import {Carousel, Slide} from "vue-carousel";
import CardsCarousel from "@/app/components/CardsCarousel";
import TestimonialsVideo from "@/app/components/TestimonialsVideo";
import ContextualArticle from "@/app/components/ContextualArticle";
import HeaderSearchForm from "@/app/components/HeaderSearchForm";
import ObfuscatedLink from "@/app/components/ObfuscatedLink";

Vue.config.productionTip = false;

// eslint-disable-next-line no-new
new Vue({
    comments: true,
    el: '#app',
    components: {
        HeaderNav,
        Carousel,
        Slide,
        CardsCarousel,
        TestimonialsVideo,
        ContextualArticle,
        HeaderSearchForm,
        RemoteVideo,
        ObfuscatedLink,
    },
});

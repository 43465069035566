<template>
    <div class="header-search-form flex items-center">
        <transition name="icon-fade">
            <button
                v-if="!isOpened"
                class="header-search-form__search-icon w-8 h-8 p-0 bg-transparent border-0"
                :aria-label="openAriaLabel"
                @click="openSearchBlock"
            ></button>
        </transition>

        <reveal>
            <div
                v-if="isOpened"
                class="header-search-form__form-container"
            >
                <form
                    action="/recherche"
                    method="GET"
                    class="absolute inset-x-4 inset-y-3 md:inset-0 flex items-center border-b-2 border-black pb-2"
                >
                    <button
                        class="flex-shrink-0 header-search-form__submit header-search-form__search-icon bg-transparent border-0"
                        type="submit"
                        :aria-label="submitAriaLabel"
                    ></button>
                    <input
                        ref="input"
                        type="text"
                        name="terme"
                        class="flex-1 min-w-0 font-roboto text-base leading-normal bg-transparent focus:outline-none"
                        :placeholder="placeholder"
                    >
                    <button
                        class="flex-shrink-0 header-search-form__cross w-4 h-8 bg-center bg-no-repeat"
                        :aria-label="closeAriaLabel"
                        @click.prevent="isOpened = false"
                    ></button>
                </form>
            </div>
        </reveal>
    </div>
</template>

<script>
import Reveal from "@/app/transitions/Reveal";

export default {
    name: "HeaderSearchForm",

    props: {
        openAriaLabel: {
            type: String,
            default: 'Ouvrir le champ recherche'
        },
        closeAriaLabel: {
            type: String,
            default: 'Fermer le champ recherche'
        },
        submitAriaLabel: {
            type: String,
            default: 'Rechercher'
        },
        placeholder: {
            type: String,
            default: 'Recherche'
        },
    },

    components: {
        Reveal,
    },

    data() {
        return {
            isOpened: false,
        }
    },

    methods: {
        openSearchBlock() {
            this.isOpened = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.header-search-form {
    &__search-icon {
        @apply border-0 bg-transparent bg-center bg-cover focus:outline-none focus:ring-2 focus:ring-brand;
        background-image: url('~@/static/images/icons/search.svg');
    }

    &__form-container {
        @apply overflow-hidden absolute inset-x-0 top-0 w-full h-16 bg-white md:relative md:w-auto md:h-10;

        @screen md {
            width: 12.5rem;
        }
    }

    &__submit {
        @apply w-6 h-6 mr-2 p-0;
        background-image: url('~@/static/images/icons/search.svg');
    }

    &__cross {
        background-size: 1rem 1rem;
        background-image: url('~@/static/images/icons/cross-gray.svg');
    }

    .icon-fade-enter-active {
        transition: opacity 200ms ease;
        transition-delay: 200ms;
    }
    .icon-fade-enter {
        opacity: 0;
    }
}
</style>

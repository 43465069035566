<template>
    <intersect :threshold="[0]" root-margin="0px 0px 0px 0px" @enter="onEnter" @leave="onLeave" v-if="isRootContextualArticle">
        <div class="contextual-article">
            <pulse-loader class="flex justify-center m-20"
                          :loading="isLoading"
                          color="#0FF"
                          size="20px"
            />
            <slot></slot>
        </div>
    </intersect>
</template>

<script>
import Intersect from "vue-intersect";
import axios from 'axios';
import Vue from 'vue/dist/vue.esm.js';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    name: "ContextualArticle",

    components: {
        Intersect,
        PulseLoader,
    },
    props: {
        url: { type: String, required: true },
    },

    data() {
        return {
            isRootContextualArticle: true,
            isLoading: false,
            isAlreadyLoaded: false,
            hasFailedLoading: false,
            likeButton: null,
            originalMetaTitle: null,
            originalMetaDescription: null,
            contextualArticleMetaTitle: null,
            contextualArticleMetaDescription: null,
            originalUrl: null,
            metaDescriptionNode: null,
        }
    },

    methods: {
        async onEnter() {
            try {
                await this.loadArticle();
            } catch (error) {
                console.error(`Couldn't load contextual article: `, error);
                this.hasFailedLoading = true;
            }

            if (this.hasFailedLoading) {
                return;
            }

            this.likeButton.classList.remove('hidden');

            // Update the URL
            this.originalUrl = document.location.href;
            history.replaceState({}, '', this.url);

            // Replace title
            this.originalMetaTitle = document.title;
            document.title = this.contextualArticleMetaTitle;

            // Replace meta description
            this.originalMetaDescription = this.metaDescriptionNode.content;
            this.metaDescriptionNode.content = this.contextualArticleMetaDescription;
        },

        onLeave() {
            if (!this.originalMetaTitle) {
                // What?! If this.originalMetaTitle is null, this means that onEnter was never called. This happens because the event is fired when instantiating an IntersectionObserver.
                return;
            }

            if (this.hasFailedLoading) {
                return;
            }

            this.likeButton.classList.add('hidden');

            // Reset the URL
            history.replaceState({}, '', this.originalUrl);

            // Reset title
            document.title = this.originalMetaTitle;

            // Reset meta description
            this.metaDescriptionNode.content = this.originalMetaDescription;
        },

        async loadArticle() {
            if (this.isAlreadyLoaded) {
                return;
            }

            this.isLoading = true;
            const { data: htmlSource } = await axios.get(this.url);
            this.isLoading = false;
            this.isAlreadyLoaded = true;

            const temporaryNode = document.createElement('div');
            temporaryNode.innerHTML = htmlSource;

            this.contextualArticleMetaDescription = temporaryNode.querySelector('meta[name=description]').content;
            this.contextualArticleMetaTitle = temporaryNode.querySelector('title').textContent;
            const articleContent = temporaryNode.querySelector('main > .layout-content');

            if (!articleContent) {
                throw new Error('Unexpected contextual article content');
            }

            let slotBuilder = Vue.extend({
                template: articleContent.innerHTML,
                components: this.$root.$options.components,
            })
            let slotInstance = new slotBuilder()
            this.$slots.default = slotInstance.$mount()._vnode
            this.$forceUpdate();

            this.$nextTick(() => {
                this.likeButton = document.querySelector('.contextual-article .article-flag-like-container');
                this.likeButton.classList.add('hidden');
            });
        },
    },

    mounted() {
        this.isRootContextualArticle = this.$parent.$options.name !== this.$options.name,
        this.metaDescriptionNode = document.querySelector('head > meta[name=description]');
    },
}
</script>
